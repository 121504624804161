<template>
  <div>
    <header-slot />
    <meta-grid-table />
  </div>
</template>
<script>
import MetaGridTable from "@/views/creative/views/meta/components/MetaGridTable.vue";
export default {
  name: "MetaMain",
  components: {
    MetaGridTable,
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
</style>